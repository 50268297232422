"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    ERROR_INVALID_SID_message: "This short name doesn't match an organization in our system. Please try again.",
    ERROR_UNEXPECTED_message: 'Sorry, an unexpected error occurred while processing your request. Please try again, or contact your administrator if the issue persists.',
    ERROR_INVALID_SID_title: 'Invalid Short Name',
    ERROR_UNEXPECTED_title: 'Unexpected Error',
    instructions: `Please enter your organization's short name to access its sign-in screen.`,
    submitButtonLabel: 'CONTINUE',
    title: 'Find Your Organization',
    sidInputLabel: 'Short Name'
};
