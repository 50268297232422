export default {
  activate: '/activate',
  activated: '/activated',
  api: '/api',
  auth: '/auth',
  authProviderCallback: '/auth/:providerSID/callback',
  authProviderConnect: '/auth/:providerSID/connect',
  authProviderSignIn: '/auth/:providerSID/sign-in',
  orgPasswordReset: '/:orgSID/password-reset',
  orgSignIn: '/:orgSID/sign-in',
  postActivateUser: '/post/activate-user',
  postFindOrg: '/post/find-org',
  postRequestPasswordReset: '/post/request-password-reset',
  postSignIn: '/post/sign-in',
  postUpdatePassword: '/post/update-password',
  providerConnected: '/:providerSID/connected',
  signIn: '/sign-in',
  updatePassword: '/update-password'
};
